<nav class="navbar navbar-expand-lg navigation-bar navbar-dark client-nav mobile">
  <!-- Public docs logo -->
  <ng-container>
    <div class="w-100 d-flex" [ngClass]="isDesktop ? 'justify-content-center' : 'justify-content-left'">
      <div class="w-sm-68 w-80">
        <div class="row">
          <div class="col-8 d-flex flex-column justify-content-center align-items-center">
            <div class="w-690">
              <a class="navbar-brand" [href]="logoDestination">
                <img *ngIf="activeBrand === 'deudafix'" class="img-fluid img"
                     src="assets/img/logos/deudafix-logo-new.png" alt="Deudafix Logo">
                <img *ngIf="activeBrand === 'unifye'" class="img-fluid img"
                     src="assets/img/logos/unified-logo.png" alt="Unifye Logo">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nav>
