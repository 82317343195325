import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector:    'app-public-client-nav',
  templateUrl: './public-client-nav.component.html',
  styleUrls:   ['./public-client-nav.component.scss'],
})
export class PublicClientNavComponent implements OnInit{
  @Input() isDesktop: boolean;
  @Input() publicRouteLogoVisible = false;
  @Input() logoDestination: string;
  @Input() activeBrand: 'deudafix' | 'unifye';


  constructor() {
  }

  ngOnInit(): void {
    if (!this.activeBrand){
      this.activeBrand = 'deudafix';
    }
  }
}
