import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientAuthGuard } from './_shared/guards/client-auth-guard.service';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
  {path: 'login', pathMatch: 'full', redirectTo: 'auth/login'}, // TODO: remove temp workaround when auth guard is fixed
  {
    path:         'dashboard',
    loadChildren: () => import('./client-dashboard/client-dashboard.module').then(m => m.ClientDashboardModule),
    canActivate:  [ClientAuthGuard],
    canLoad:      [ClientAuthGuard]
  },
  {
    path:         'profile',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canLoad:      [ClientAuthGuard]
  },
  {
    path:         'payments',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
    canLoad:      [ClientAuthGuard]
  },
  {
    path:         'documents',
    loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
    canLoad:      [ClientAuthGuard]
  },
  {
    path:         'onboarding',
    loadChildren: () => import('./case-relation-validation/case-relation-validation.module').then(
      m => m.CaseRelationValidationModule),
    canLoad:      [ClientAuthGuard]
  },
  {path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)},
  {path: 'auth', loadChildren: () => import('./client-auth/client-auth.module').then(m => m.ClientAuthModule)},
  { path: 'case', loadChildren: () => import('./case/case.module').then(m => m.CaseModule) },
];

@NgModule({
  imports:   [RouterModule.forRoot(routes, {})],
  exports:   [RouterModule],
  providers: [ClientAuthGuard],
})
export class AppRoutingModule {
}
