<ng-container *ngIf="showNav">
  <!-- Client nav -->
  <app-client-nav *ngIf="authUser?.role?.slug === 'user' && !publicNav && selectedCase" [isDesktop]="isDesktop"
                  [selectedCase]="selectedCase" [showWhatsAppButton]="showWhatsAppButton" ></app-client-nav>
  <!-- Login and Public routes nav -->
  <app-public-client-nav *ngIf="publicNav || !authUser && logoLoaded" [logoDestination]="logoDestination" [activeBrand]="activeBrand"
                         [isDesktop]="isDesktop" [publicRouteLogoVisible]="publicNav"
  ></app-public-client-nav>
</ng-container>

