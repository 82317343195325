import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../_base-shared/models/User/User';
import { ScriptLoaderService } from '../../../../../_base-shared/services/script-loader.service';
import { environment } from '../../../environments/environment';
import { ClientGlobalEventService } from '../../_shared/services/client-global-event.service';
import { ClientAppService } from '../../client-app.service';
import { ClientAuthService } from '../../client-auth/client-auth.service';

@Component({
  selector:    'app-nav',
  templateUrl: './nav.component.html',
  styles:   [],
})
export class NavComponent implements OnInit {
  @Input() selectedCase: Case;
  public authUser: User;
  public showNav                = true;
  public logoLoaded             = false;
  public logoDestination: string;
  public isDesktop: boolean;
  public showWhatsAppButton     = false;
  public publicRouteLogoVisible = false;
  public publicNav = false;
  public activeBrand: 'deudafix' | 'unifye';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private breakpointObserver: BreakpointObserver,
              @Inject(DOCUMENT) private document: Document,
              private globalEventsService: ClientGlobalEventService
  ) {
  }

  ngOnInit(): void {
    if (this.router.url !== '/') {
      this.publicRouteLogoVisible = this.router.url.includes('public');
      this.publicNav = this.router.url.includes('public');
      this.logoLoaded             = true;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.publicRouteLogoVisible = this.router.url.includes('public');
        this.publicNav = this.router.url.includes('public');
        this.logoLoaded             = true;
      }
    });
    this.globalEventsService.navStatus$.subscribe(result => this.showNav = result);
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.isDesktop = result.matches;
    });

    this.showWhatsAppButton = true;
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this.showWhatsAppButton = event.url === '/';
    });
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
    });
    this.globalEventsService.activeBrand.subscribe(result => {
      if (result) {
        this.activeBrand = result;
        if (this.activeBrand === 'deudafix') {
          this.logoDestination = environment.APP_ENV === 'local' ? environment.APP_URL : 'https://www.deudafix.es';
        } else {
          this.logoDestination = environment.APP_ENV === 'local' ? environment.APP_URL : 'https://www.unifye.es/';
        }
      }
    });
  }
}
