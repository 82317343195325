import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../../_base-shared/models/User/User';
import { ClientGlobalEventService } from '../../../_shared/services/client-global-event.service';
import { ClientAppService } from '../../../client-app.service';
import { ClientAuthService } from '../../../client-auth/client-auth.service';

interface NavItem {
  name: string;
  link: string | string[];
  icon: string;
}

@Component({
  selector: 'app-client-nav',
  templateUrl: './client-nav.component.html',
  styleUrls: ['./client-nav.component.scss']
})

export class ClientNavComponent implements OnInit{
  @Input() isDesktop: boolean;
  @Input() showWhatsAppButton: boolean;
  @Input() selectedCase: Case;
  @Input() publicRouteLogoVisible = false;

  public navV2                  = false;
  public authUser: User;
  public navItems: Array<NavItem> = [];

  constructor(private globalEventsService: ClientGlobalEventService,
              private clientAuthService: ClientAuthService,
              private clientAppService: ClientAppService,
              private router: Router
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.clientAppService.navSubject$.subscribe(isNavV2 => {
      this.navV2 = isNavV2;
    });
    if (this.selectedCase){
      this.navItems = [
        { name: 'Inicio', link: '/', icon: 'home' },
        { name: 'Tu caso', link: ['/onboarding', 'cases', this.selectedCase?.uuid, 'users', this.authUser?.uuid, 'your-case'], icon: 'card_travel' },
        { name: 'Docs', link: ['/documents', 'cases', this.selectedCase.uuid, 'users', this.authUser?.uuid, 'document-upload'], icon: 'description' },
        { name: 'Pagos', link: ['/payments', 'cases', this.selectedCase.uuid, 'payment-handler'], icon: 'payment' },
        { name: 'Perfil', link: ['/profile', 'cases', this.selectedCase.uuid, 'profile'], icon: 'person' },
        { name: 'Salir', link: '/logout', icon: 'logout' }
      ];
    }
  }


  public logOut(): void {
    this.clientAuthService.logout().subscribe(res => {
      this.globalEventsService.setAuthUser(null);
      this.router.navigateByUrl('/auth/login');
    });
  }

  public changeNav(): void {
    this.clientAppService.setNavSubject(false);
    this.clientAppService.navSubject$.subscribe(data => {
      if (!data) {
        this.navV2 = data;
      }
    });
  }
}
