<ng-container>
  <nav class="navbar navbar-expand-lg navigation-bar navbar-dark client-nav mobile">
    <div class="flex-lg-row" [ngClass]="navV2 && !isDesktop ? 'd-flex-start-sm': 'd-flex-between'">
      <div class="navbar-brand w-125">
        <a>
          <img src="assets/img/logos/deudafix-logo-new.png" [ngClass]="navV2 && !isDesktop ? 'img-fluid img margin-img-small': 'img-fluid img margin-img'" alt="Deudafix Logo">
        </a>
      </div>
      <a (click)="changeNav()" *ngIf="navV2 && !isDesktop" class="d-flex flex-row justify-content-start align-items-center back-btn" routerLink="/client/dashboard">
        <mat-icon>keyboard_arrow_left</mat-icon>
        {{ 'SHARED.home' | translate }}
      </a>
      <div *ngIf="!isDesktop && showWhatsAppButton && !navV2">
        <a type="button" class="btn btn-link mr-2 whatsapp-btn" href="https://wa.me/34960136099">
          <i class="far fa-comment-dots whatsapp-icon"></i>&nbsp;&nbsp;&nbsp;Whatsapp</a>
      </div>
    </div>
    <!-- Desktop Nav -->
    <div class="collapse navbar-collapse client-nav-desktop"  *ngIf="isDesktop">
      <ul class="navbar-nav mr-auto">
        <div class="d-flex flex-row justify-content-around w-43">
          <div *ngFor="let item of navItems;  let last = last"  [routerLink]="item.link" (click)="last && logOut()"
               class="d-flex flex-row align-items-center justify-content-center">
            <mat-icon class="blue-icon">{{item.icon}}</mat-icon>
            <span class="client-nav-label" routerLinkActive="route-active"
                  [routerLinkActiveOptions]="{ exact: true }">{{item.name}}</span>
          </div>
        </div>
      </ul>
    </div>
    <!--Mobile nav-->
    <mat-toolbar *ngIf="!isDesktop" class="mobile-nav nav-d-block">
      <div *ngFor="let item of navItems;  let first = first; let last = last" routerLink="/" (click)="last && logOut()"
           class="d-flex flex-column align-items-center justify-content-center" (click)="first && changeNav()"
           [routerLink]="item.link">
        <mat-icon>{{item.icon}}</mat-icon>
        <a routerLinkActive="route-active" [routerLinkActiveOptions]="{ exact: true }">
          {{item.name}}</a>
      </div>
    </mat-toolbar>
  </nav>
</ng-container>
